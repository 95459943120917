import React from "react"
import Layout from "../../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import PageHeader from "../../components/PageHeader"
import Footer from "../../components/Footer"
import Seo from "../../components/seo"
export default function FinancialCalendarPage() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 291 }) {
        id
        content
        title
      }
    }
  `)
  const { content } = data.wpPage
  const pageTitle = "Financial Calendar"

  return (
    <Layout isInvestor pageTitle={pageTitle}>
      <Seo title={pageTitle} />
      <div className="max-w-3xl mx-auto investor-container">
        <div className="py-8 md:py-16">
          <PageHeader text="Financial Calendar" />
          <div
            dangerouslySetInnerHTML={{ __html: content }}
            className="space-y-4"
          />
        </div>
      </div>
      <Footer />
    </Layout>
  )
}
